<template>
<div class="animated fadeIn">
    <products-worker v-if="showProductsWorker" :baseUrl="baseUrl"></products-worker>
    <products-customer v-if="showProductsCustomer" :baseUrl="baseUrl"></products-customer>
</div>
</template>

<script>
import _ from 'lodash'
import ProductsWorker from './ProductsWorker'
import ProductsCustomer from './ProductsCustomer';
export default {
    name: 'Products',
    components: {
        'products-worker': ProductsWorker,
        'products-customer': ProductsCustomer
    },
    data: function () {
        return {
            baseUrl: baseUrl,
            enableCard: false,
        }
    },
    computed: {
        showProductsWorker: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableCard;
            }
        },
        showProductsCustomer: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return !this.enableCard;
            }
        }
    },
}
</script>
