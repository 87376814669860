<template>
  <div class="container">
  <br>
    <b-card>
    <div slot="header">
      <h3>
        <strong>{{translate('shop_product')}}</strong>
      </h3>
    </div>
     <b-form>
        <b-col>
            <b-row> 
              <b-col class="text-right">
                <b-button @click.prevent="closePanel" type="button" variant="light">{{translate('cancel')}}</b-button>
              </b-col>
            </b-row>
        </b-col>
      </b-form>
    </b-card>  
  </div>
</template>


<script>
export default {
  name: 'BuyProduct',
  data() {
    return {
      msg: '',
    };
  },
 
  methods: {

    closePanel() {
      this.$emit('closePanel', {});
    }
  }
}
</script>

