<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div :class="'pull-right'">
                    <q-btn class="mr-2" :label="!$q.screen.lt.sm ? translate('delete') : ''" no-caps dense color="danger" @click.prevent="showDeleteProductPanel(product_id)" icon="delete" flat />
                    <toggle-active @closePanel="closePanel()" :no_label="$q.screen.lt.sm ? true : null" :is_active="is_active" :patch_toggle_route="patch_toggle_route" :item_id="product_id" :entity_type="entity_type" />
                </div>
                <div class="m-1"><q-icon name="edit" class="mr-1" />{{ translate('edit_product') }}</div>
            </template>
            <b-list-group class="list-group-accent overflow-hidden">
                <b-form @submit.prevent>
                    <b-list-group-item class="p-0 pt-2">
                        <q-input :label="translate('name')" dense square color="primary" type="text" id="name" v-model="product.name" :placeholder="translate('enter_product')" />
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input color="primary" :label="translate('basic_price')" square dense @wheel="$event.target.blur()" type="number" step="0.01" id="basic_price" v-model.number="product.base_price" :placeholder="translate('enter_basicPrice')">
                                    <template v-if="currency" v-slot:append><small>{{ currency }}</small></template>
                                </q-input>
                            </div>
                            <div class="col-md p-0">
                                <q-input color="primary" :label="translate('subscription_price')" square dense @wheel="$event.target.blur()" type="number" step="0.01" id="subscription_price" v-model.number="product.subscription_price" :placeholder="translate('enter_subscriptionPrice')">
                                    <template v-if="currency" v-slot:append><small>{{ currency }}</small></template>
                                </q-input>
                            </div>
                        </div>
                        <q-input :label="translate('description')" :placeholder="translate('enter_description')" v-model="product.description" square dense color="primary" type="textarea" rows="2" />
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info">
                        <custom-images :delete_route="delete_images_route" :entity_type="entity_type" :get_route="get_route" :post_route="post_route" :item_id="product_id" :item_images="product.images" />
                    </b-list-group-item>
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-secondary cursor-pointer bg-light font-weight-bold text-uppercase small" @click.stop.prevent="isHidden = !isHidden">
                        <i class="fas fa-history"></i> {{translate('price_history')}}
                    </b-list-group-item>
                    <b-list-group-item v-if="!isHidden && prices" class="list-group-item-accent-info">
                        <q-item class="list-item full-width" v-for="(price, i) in prices" :key="'price-'+i+'-'+price.id">
                            <q-item-section side>
                                <span><i class="fas fa-calendar"></i> <strong>{{ translate('valid_from') }}</strong></span>
                                {{ price.valid_from }}
                            </q-item-section>
                            <q-item-section side><strong>{{ translate('basic_price') }}</strong>{{price.base_price}}</q-item-section>
                            <q-item-section side><strong>{{ translate('subscription_price') }}</strong>{{price.subscription_price}}</q-item-section>
                        </q-item>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="editProduct">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import CustomImages from '../../../images/CustomImages'
import DeleteProduct from './DeleteProduct'
import ToggleActive from '../../../template-parts/ToggleActive'

export default {
    name: 'EditProduct',
    props: ['product_id', 'is_active', 'baseUrl', 'entity_type', 'patch_toggle_route', 'delete_route'],
    components: {
        'custom-images': CustomImages,
        'toggle-active': ToggleActive,
    },
    computed: {
        post_route: function () {
            return baseUrl + 'products/images'
        },
        get_route: function () {
            return baseUrl + 'products'
        },
        delete_images_route: function () {
            return baseUrl + 'products/images'
        },
    },
    data: function () {
        return {
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            slide: 1,
            fullscreen: false,
            isHidden: true,
            product: {},
            prices: {},
            page: 1,
            msg: null,
        }
    },
    created: function () {
        this.getProduct()
        this.getPrices()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        showDeleteProductPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeleteProduct,
                props: {
                    product_id: id,
                    delete_route: this.delete_route,
                    entity_type: this.entity_type
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        }) 
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        getProduct: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.get(baseUrl + 'products/' + this.product_id, {
                    headers: headers
                })
                .then(response => {
                    this.product = response.data.item
                    this.product.images = this.product.images.map(image => {
                        image['image_name'] = baseUrl + image.image_name
                        return image
                    })
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getPrices: function () {
            var pricesURL = baseUrl +
                'products/prices?product_id=' + this.product_id +
                '&page=' + this.page +
                '&items_per_page=' + this.$items_per_page

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            axios.get(pricesURL, {
                    headers: headers
                })
                .then(response => {
                    this.prices = response.data.prices
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        editProduct: function () {
            var product_id = this.product_id
            var product_name = this.product.name
            var data = {
                'name': product_name,
                'description': this.product.description,
                'base_price': this.product.base_price,
                'subscription_price': this.product.subscription_price,
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.put(baseUrl + 'products/' + product_id, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'edit_product_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true,
                            product: { id: product_id, name: product_name }
                        })                        
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
</style>
