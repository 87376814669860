<template>
<div>
    <b-card class="m-0" no-body>
        <div slot="header">
            <h3>{{translate('products')}}</h3>
        </div>
        <search-wrapper-card :active="product_active" :activeStatus="activeStatus" :inactive="product_inactive" :inactiveStatus="inactiveStatus" form_type="products">
            <q-input slot="search_input" bottom-slots @input="isTyping = true" v-model="searchQuery" id="filternameproducts" class="search-input" type="text" :placeholder="translate('find_product')" :dense="true">
                <template v-slot:prepend>
                    <q-icon name="search" />
                </template>
            </q-input>
            <template slot="add_button">
                <q-btn no-caps v-if="!$q.screen.lt.sm" color="primary" @click.prevent="showAddProductPanel">
                    <i class="fa fa-plus mr-1"></i> {{ translate('add_product') }}
                </q-btn>
                <q-btn dense round v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" @click.prevent="showAddProductPanel" />
            </template>
        </search-wrapper-card>
    </b-card>
    <b-list-group class="list_wrapper-products">
        <draggable v-model="products" group="products" handle=".handle" class="list-group" v-bind="dragOptions" @start="startDrag" @end="endDrag" @change="setItemPosition" :sort="true">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                <q-item v-for="(product, i) in products" :key="'product-item-' + i + '-' + (i.id,product.id)" :id="product.id" class="list-item full-width draggable-item" 
                    :class="product.is_active ? 'product-active' : 'product-inactive'">
                    <q-item-section v-if="!$q.screen.lt.sm" side class="pl-0 handle">
                        <q-btn size="sm" flat color="dark" icon="fas fa-grip-horizontal" class="bg-blue-2 cursor-move full-width full-height" :label="i+1" stack>
                            <q-tooltip :offset="[10, 10]">{{ translate('drag_and_drop_to_change_order') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section class="p-0" side>
                        <div class="image-thumbnail cursor-pointer handle" @click.prevent="showEditProductPanel(product.id, product.is_active)">
                            <img v-if="product.main_image_link" :src="product.main_image_link" />
                            <i v-else class="fa fa-photo fa-2x"></i>
                        </div>
                    </q-item-section>
                    <q-item-section class="full-height align-self-center pt-2 pb-2">
                        <strong class="text-muted">{{ product.name }}</strong>
                    </q-item-section>
                    <!-- [+] Desktop sections. -->
                    <template v-if="!$q.screen.lt.sm">
                        <q-item-section side class="full-height align-self-center pt-2 pb-2">
                            <q-btn round icon="edit" color="primary" @click="showEditProductPanel(product.id, product.is_active, product.name)">
                                <q-tooltip :offset="[10, 10]">{{ translate('edit_' + entity_type) }}</q-tooltip>
                            </q-btn>
                        </q-item-section>
                        <q-item-section side class="full-height align-self-center pt-2 pb-2">
                            <q-btn round icon="delete" color="danger" @click="showDeleteProductPanel(product.id)">
                                <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                            </q-btn>
                        </q-item-section>
                        <q-item-section side class="full-height align-self-center pt-2 pb-2">
                            <toggle-active :is_active="product.is_active" :no_label="true" :patch_toggle_route="patch_toggle_route" :item_id="product.id" :entity_type="entity_type" />
                        </q-item-section>
                    </template>
                    <!-- [-] Desktop sections. -->
                </q-item>
            </transition-group>
        </draggable>
    </b-list-group>
    <infinite-loading :identifier="infProducts" slot="append" @infinite="getProducts" />
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../main'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'
import AddProduct from './forms/AddProduct'
import EditProduct from './forms/EditProduct'
import ProductDetails from './show/ProductDetails'
import DeleteProduct from './forms/DeleteProduct'
import BuyProduct from './forms/BuyProduct'
import draggable from 'vuedraggable'
import ToggleActive from '../../template-parts/ToggleActive'

export default {
    name: 'ProductsWorker',
    props: ['baseUrl'],
    components: {
        'search-wrapper-card': SearchWrapperCard,
        'toggle-active': ToggleActive,
        draggable,
    },
    data: function () {
        return {
            infProducts: 2020,
            drag: false,
            products: [],
            product_active: 'active',
            product_inactive: '',
            searchQuery: '',
            order_by: '',
            isTyping: false,
            page: 1,
            enableButon: false,
            state: {}
        }
    },
    mounted: function () {
        eventBus.$on('update_image_product', (image) => {
            this.updateMainImageLink(image)
        })
        eventBus.$on('toggle_active_product', (item) => {
            this.toggleActiveProduct(item)
        })
        eventBus.$on('delete_product', (id) => {
            this.deleteProduct(id)
        })
    },
    computed: {
        entity_type: function () {
            return 'product'
        },
        patch_toggle_route: function () {
            return baseUrl + 'products'
        },
        delete_route: function () {
            return baseUrl + 'products'
        },
        dragOptions() {
            return {
                animation: 0,
                group: "products",
                disabled: false,
                ghostClass: "ghost"
            }
        },
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        },
    },
    methods: {
        updateProductName: function (item) {
            // Find index of specific object using findIndex method,
            var objIndex = this.products.findIndex((obj => obj.id === item.product.id))
            // and update the element from the products array.
            this.products[objIndex]['name'] = item.product.name ? item.product.name : ''
        },
        toggleActiveProduct: function (item) {
            if (item.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.products.findIndex((obj => obj.id === item.id))
                if (this.products[objIndex]) {
                    // And update is_active for the given item in list.
                    this.products[objIndex]['is_active'] = item.is_active ? true : false
                    eventBus.$emit('update_toggle_' + this.entity_type, item)

                    if (item.is_active === 0 && this.product_active === 'active' && this.product_inactive === '') {
                        this.deleteProduct(item.id)
                    }
                    if (item.is_active === 1 && this.product_active === '' && this.product_inactive === 'inactive') {
                        this.deleteProduct(item.id)
                    }
                }
            }
        },
        deleteProduct: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.products.findIndex((obj => obj.id === id))
            // and remove the element from the products array.
            this.products.splice(objIndex, 1)
        },
        updateMainImageLink: function (image) {
            if (image.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.products.findIndex((obj => obj.id === image.id))
                // And update main_image_link for the given item in list.
                if (!this.products[objIndex]['main_image_link']) {
                    this.products[objIndex]['main_image_link'] = ''
                }
                this.products[objIndex]['main_image_link'] = image.image_link
            }
        },
        resetMainList: function () {
            this.page = 1
            this.products = []
            if (this.products && this.products.length === 0) {
                // Force update using a new request.
                this.getProducts(this.state)
            }
        },
        startDrag: function (element, event) {
            this.isDragging = true
        },
        endDrag: function (element) {
            this.isDragging = false
        },
        getDropIndex: function (event) {
            var drop_index = event.oldIndex
            if (event.newIndex > event.oldIndex + 1) {
                drop_index = event.newIndex - 1
            }
            if (event.newIndex < event.oldIndex - 1) {
                drop_index = event.newIndex + 1
            }
            return drop_index
        },
        setItemPosition: function (event) {
            var product_drag_id = event.moved.element.id
            var products = this.products
            var accessToken = this.accessToken()
            var drop_index = this.getDropIndex(event.moved)
            var product_drop_id = products[drop_index].id

            var data = {
                "product_drag_id": product_drag_id,
                "product_drop_id": product_drop_id
            }
            var headers = {
                'Authorization': 'Bearer ' + accessToken,
            }
            axios.post(baseUrl + 'products/position', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    if (response.data.status) {
                        this.$toasted.success('The positions of your products have been updated', {
                            duration: this.$toasted_duration
                        })
                    } else {
                        this.$toasted.error('The data was not updated', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        activeStatus: _.debounce(function () {
            if (this.product_active === 'active') {
                this.product_active = ''
            } else {
                this.product_active = 'active'
            }
            this.resetMainList()
        }, 200),
        inactiveStatus: _.debounce(function () {
            if (this.product_inactive === 'inactive') {
                this.product_inactive = ''
            } else {
                this.product_inactive = 'inactive'
            }
            this.resetMainList()
        }, 200),
        getProductsUrl: function () {
            var productUrl = baseUrl +
                'products?page=' + this.page +
                '&items_per_page=' + this.$items_per_page +
                (this.searchQuery ? '&search=' + this.searchQuery : '')

            if (this.product_active === 'active' && this.product_inactive === '') {
                productUrl += '&product_status=' + this.product_active
            }
            if (this.product_inactive === 'inactive' && this.product_active === '') {
                productUrl += '&product_status=' + this.product_inactive
            }

            return productUrl
        },
        getProducts: function ($state) {
            var url = this.getProductsUrl()

            if (url) {
                var headers = {
                    'Authorization': 'Bearer ' + this.accessToken(),
                }
                this.state = $state
                // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                let products = this.products
                axios.get(url, {
                        headers: headers
                    })
                    .then(response => {
                        $state = this.state
                        if (response.data.items && response.data.items.length > 0) {
                            products.push(...response.data.items.map(item => {
                                if (item.main_image_link) {
                                    item['main_image_link'] = baseUrl + item.main_image_link
                                }
                                return item
                            }))
                        }
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                            this.products = products
                            $state.complete()
                        }
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
            }
        },
        showDeleteProductPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeleteProduct,
                props: {
                    product_id: id,
                    delete_route: this.delete_route,
                    entity_type: this.entity_type
                }
            })
        },
        showAddProductPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddProduct,
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        showEditProductPanel: function (id, is_active) {
            const panelInstance = this.$showPanel({
                component: EditProduct,
                props: {
                    product_id: id,
                    entity_type: this.entity_type,
                    patch_toggle_route: this.patch_toggle_route,
                    delete_route: this.delete_route,
                    is_active: is_active
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateProductName(result)
                    }
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
    transform-origin: center;
    transform: scale(.5);
}

.list-group {
    min-height: 20px;
}

.list-group-item {
    cursor: move;
}

.search-input {
    margin-left: -15px !important;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: border-color .1s ease-in-out;

    &:first-child {
        margin-top: .5rem;
    }

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &.draggable-item:hover {
        border-style: dotted;
        border-color: rgba(0, 0, 0, .85);
    }
}


.product-active {
    border-left: 2px solid var(--q-color-primary)!important;
}
.product-inactive {
    border-left: 2px solid var(--q-color-dark)!important;
}
</style>
