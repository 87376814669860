<template>
<div class="animated fadeIn">
    <b-card class="m-0" no-body>
        <div slot="header">
            <h3>{{translate('products')}}</h3>
        </div>
    </b-card>
    <offers-list :entity_type="entity_type" :get_route="get_route" :buy_panel_title="buy_panel_title" :list="products" :baseUrl="baseUrl" :showDetailPanel="showDetailPanel" />
    <infinite-loading slot="append" @infinite="getProduct" />
</div>
</template>

<script>
import OffersList from '../../template-parts/OffersListCustomer'
import _ from 'lodash'
import ProductDetails from './show/ProductDetails'

export default {
    name: 'ProductsCustomer',
    props: ['baseUrl'],
    components: {
        'offers-list': OffersList
    },
    computed: {
        entity_type: function () {
            return 'product'
        },
        get_route: function () {
            return baseUrl + 'products'
        },
        buy_panel_title: function () {
            return this.translate('buy_product')
        },
    },
    data: function () {
        return {
            slide: 1,
            products: [],
            product_active: 'active',
            product_inactive: '',
            order_by: '',
            page: 1,
            state: {},
        }
    },
    methods: {
        getProduct: function ($state) {
            var productUrl = baseUrl +
                'products?page=' + this.page +
                '&items_per_page=' + this.$items_per_page +
                '&product_status=' + this.product_active
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(productUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.products.push(...response.data.items)
                    } 
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDetailPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: ProductDetails,
                props: {
                    baseUrl: this.baseUrl,
                    product_id: id
                }
            })
        }
    }
}
</script>
