<template>
<offers-details-container :item="product" :item_id="product_id" :baseUrl="baseUrl" :closePanel="closePanel" />
</template>

<script>
import OffersDetailsContainer from '../../../template-parts/OffersDetailsContainerCustomer'

export default {
    name: 'ProductDetails',
    props: ['product_id', 'baseUrl'],
    components: {
        'offers-details-container': OffersDetailsContainer
    },
    data() {
        return {
            product: {
                name: null,
                description: '',
            },
            msg: null,
        }
    },
    created() {
        this.getProduct()
    },
    methods: {
        getProduct: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            axios.get(baseUrl + 'products/' + this.product_id, {
                    headers: headers
                })
                .then(response => {
                    this.product = response.data.item
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
